import { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Chat.module.css';
import styleMsg from '../../style/styles/Message.module.css';
// import sendIcon from '../../images/send_icon.svg';
import sendIcon from '../../images/icn_send_24.png';
import sendIconActive from '../../images/send_icon_active.svg';
import sendIconDlst from '../../images/send_icon_dlst.svg';
import CircularProgress from '@mui/material/CircularProgress';
import gentooSymbol from '../../images/gentoo_symbol.png';

import Message from './MessageTextBase';
import { addComma, getServiceType, randomText } from '../../utils/utils';
import { fetchChatHistory, fetchFloatingProduct, getChatbotReply, logEvent, resetQueryCondition } from '../../utils/apis';
import { CarouselFAB, ShortcutBanner } from '../CarouselFAB';
import { Header, HeaderMobile } from '../Header/Header';
import ExampleGroup from '../Chat/Example';
import { Progress, ProgressDLST } from '../Chat/Skeleton';
import { gtagCarouselClick, gtagClickPickup, gtagSendMessage } from '../../utils/gtag';
import MarkDownConverter from '../Markdown';

const ChatFieldBaseSDK = (props) => {
    let { customProperty, customSet = false, variant, floatingDataset, reqParams } = props;
    const chatURL = customProperty.apiUrls.chatURL;
    const resetURL = customProperty.apiUrls.resetURL;
    const apiKey = customProperty.apiKey;
    const clientId = customProperty.clientId;
    const loadURL = customProperty.apiUrls.loadURL;
    const userEventURL = customProperty.apiUrls.userEventURL;
    const recommendURL = customProperty.apiUrls.recommendURL;
    const chatUserId = reqParams.chatUserId;

    //COMM: Change page title
    document.title = '나만의 AI 쇼핑 메이트 젠투';
    if (customSet) {
        document.documentElement.style.setProperty('--color-prim-800', customSet.colorInput.color0);
        document.documentElement.style.setProperty('--color-prim-200', customSet.colorInput.color1);
        document.documentElement.style.setProperty('--color-prim-100', customSet.colorInput.color2);
    }
    const activeSendIcon = clientId === 'dlst' ? sendIconDlst : sendIconActive;

    let [messages, setMessages] = useState([]);
    let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    let [examLabelArr, setExamLabelArr] = useState([]);
    let [examTextArr, setExamTextArr] = useState([]);
    let [input, setInput] = useState('');
    let [isMobileDevice, setIsMobileDevice] = useState(false);
    let [isMobileView, setIsMobileView] = useState(reqParams.isMobile || window.innerWidth < 601);
    let [randomGreeting, setRandomGreeting] = useState('');
    let [resetBtn, setResetBtn] = useState(false);
    let [isInputFocused, setIsInputFocused] = useState(false);
    let [isBtnVisible, setIsBtnVisible] = useState(false);
    let [isSecondaryReply, setIsSecondaryReply] = useState(false);
    let [productIdx, setProductIdx] = useState(0);
    let [isBSActive, setIsBSActive] = useState(false);
    let [isChatStarted, setIsChatStarted] = useState(false);
    let [isMoreEnabled, setIsMoreEnabled] = useState(false);
    let [floatingProduct, setFloatingProduct] = useState();
    let [isChatGuideVisible, setIsChatGuideVisible] = useState(false);

    // const demoIdParam = useParams().demoId;
    // const chatUserId = demoId || demoIdParam;
    // const chatUserId = "user" + chatUserId;
    // const myId = chatUserId;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const messagesEndRef = useRef(null);
    const carouselEndRef = useRef(null);
    const inputRef = useRef(null);

    const onClick = async () => {
        if (isGeneratingAnswer) return;
        if (input === '') return;
        const itemId = sessionStorage.getItem('itemId');
        const userCount = messages.filter(item => item.userId !== 'Accio').length;
        let message = [{id: userCount, user: chatUserId, message: input, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(input, chatUserId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId, reqParams.isMobile);
        setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup}));
        if (clientId !== 'dlst' && chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', chatUserId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId, reqParams.isMobile);
            setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }

        gtagSendMessage(userCount, "Manual Input", input, clientId)
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isGeneratingAnswer) return;
            onClick();
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        if (isGeneratingAnswer) return;
        setInput(e.currentTarget.value);
    };

    //COMM: Auto scroll to bottom when new message is present
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    const handleButtonClick_send = async (e, text, itemId = 'general') => {
        e.stopPropagation();
        if (isGeneratingAnswer) return;
        const userCount = messages.filter(item => item.userId !== 'Accio').length;
        let message = [{id: userCount, user: chatUserId, message: text, time: new Date().toString() }];
        setMessages((prev) => prev.concat(message));
        setInput('');
        const chatbotReply = await getChatbotReply(text, chatUserId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId, reqParams.isMobile);
        setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text, example: chatbotReply?.example, product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup }));
        if (clientId !== 'dlst' && chatbotReply.product && chatbotReply.product.length > 0) {
            setIsSecondaryReply(true);
            const secondaryReply = await getChatbotReply('이 제품을 추천한 이유를 설명해줘.', chatUserId, setIsGeneratingAnswer, chatURL, clientId, apiKey, itemId, reqParams.isMobile);
            setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: secondaryReply.text, example: secondaryReply?.example, product: secondaryReply?.product, dialId: secondaryReply?.dialogueId, intent: secondaryReply?.intent}));
            setIsSecondaryReply(false);
        }

        gtagSendMessage(userCount, "Example Message Click", text, clientId);
    };

    // input focus state delivery for web
    const sendInputFocusStatus = (state) => {
        const targetWindow = window.parent;
        const inputFocusState = {
            inputFocusState: state,
        }
        targetWindow.postMessage(inputFocusState, "*");
    }

    // input focus state delivery for iOS
    const handleInputFocus = (e) => {
        setIsInputFocused(true);
        sendInputFocusStatus(true);

        // iOS SDK로 메세지 전달
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendInputFocusState) {
            window.webkit.messageHandlers.sendInputFocusState.postMessage(`input focused`);
        } else {
            console.warn('webkit.messageHandlers is not available');
        }

         // AOS SDK로 메세지 전달
        if (window.Android && window.Android.sendInputFocusState) {
            window.Android.sendInputFocusState('input focused');
        } else {
            console.warn('Android interface is not available');
        }

        if (!isChatStarted) {
            setIsChatStarted(true);
        }
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
        sendInputFocusStatus(false);
    }

    const handleScroll = () => {
        if (carouselEndRef.current) {
            const rect = carouselEndRef.current.getBoundingClientRect();
            setIsBtnVisible(rect.top < (isMobileView ? 16 : 100));
        }
    };

    const handleClickMore = () => {
        setIsMoreEnabled(false);
    }

    // redirect state for Web
    const sendRedirectStatus = (state, url) => {
        const targetWindow = window.parent;
        const redirectState = {
            redirectState: state,
            redirectUrl: url,
        }
        targetWindow.postMessage(redirectState, "*");
    }

    useEffect(() => {
        fetchFloatingProduct(recommendURL, reqParams)
            .then(res => {
                setFloatingProduct(res);
                sessionStorage.setItem('itemIdSDK', res.product[0].itemId || '23310');
            });
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
        }, 100);
        return () => {
            clearInterval(timer);
            document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
        }
    })

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if(e.data.isChatOpen) {
                setIsChatGuideVisible(true);
                setTimeout(() => {
                    setIsChatGuideVisible(false);
                }, [3000])
            }
        })
    }, [])

    useEffect(() => {
        // if (messages.length === 1) {
        //     document.getElementById('ChatField').scrollTo(0, 0);
        // } else {
        //     scrollToBottom();
        // }
        scrollToBottom();
    }, [messages, resetBtn, isChatStarted]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsChatGuideVisible(false);
    //     }, [3000])
    // }, []);

    useEffect(() => {
        //COMM: Check whether the device is mobile or not
        setIsMobileDevice(reqParams.isMobile);

        //COMM: Move VoiceOver focus to HEADER
        setTimeout(() => {
            const header = document.getElementById('HEADER');
            if (header) {
                header.tabIndex = -1;
                header.focus();
            }
        }, 250);
    }, []);

    // API request the reset of chat history at server
    useEffect(() => {
        if (clientId !== 'viml') resetQueryCondition(chatUserId, resetURL, clientId, apiKey);
    }, [chatUserId])

    // useEffect(() => {
    //     // scroll to bottom when new msg sent
    //     if (messages.length === 1) {
    //         document.getElementById('ChatField').scrollTo(0, 0);
    //     } else {
    //         scrollToBottom();
    //     }
    // }, [messages]);

    //COMM: Set greeting text
    useEffect(() => {
        let greeting_text1;
        let greeting_text2;
        greeting_text1 = `sdk 용 페이지입ㄴ디ㅏ 상품 추천 설명 설명 설명 explanation 구구절절 설명 합리적이고 합당하고 논리적이고 감성적인 젠투의 추천 설명`;
        greeting_text2 = `sdk 보여줄 페이지에여 상품 추천 설명 설명 설명 explanation 구구절절 설명 합리적이고 합당하고 논리적이고 감성적인 젠투의 추천 설명`;

        const greeting_text = [greeting_text1, greeting_text2];
        const greeting_index = Math.floor(Math.random() * greeting_text.length);
        setRandomGreeting(greeting_text[greeting_index]);
    }, [chatUserId]);

    // Top-toggle switching style
    useEffect(() => {
        randomText(setExamLabelArr, setExamTextArr, clientId);
    }, [clientId])

    useEffect(() => {
        if (messages[messages.length - 1]?.chatUserId === 'Accio' && !messages[messages.length - 1].product) {
            setResetBtn(true)
        } else {
            setResetBtn(false)
        }
    }, [messages]);

    useEffect(() => {
        if (clientId === 'viml') fetchChatHistory(loadURL, chatUserId, clientId, '', apiKey, setMessages)
    }, []);

    useEffect(() => {
        const prodDesc = document.getElementById('PROD-DESC');
        if (prodDesc.clientHeight > 94) setIsMoreEnabled(true);
    }, [floatingProduct]);

    return (
        <div className={style.Chat__chatWrap__md}>
            <div className={style.Chat__chatTopWrap__md} style={{overflowY: 'scroll', overflowX: 'hidden'}}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: (clientId === 'dlst' ? '100%' : '161px'), height: '0px', position: 'absolute'}}>
                        {(clientId !== 'dlst') && <div className={compStyle.Spacing__16} />}
                        {
                            (isBtnVisible && clientId === 'dlst') &&
                            <ShortcutBanner 
                                carouselEndRef={carouselEndRef} 
                                productIdx={productIdx}
                                product={messages.filter(message => message.intent === '제공')}
                                chatUserId={chatUserId}
                                clientId={clientId}
                            />
                        }
                        {
                            (isBtnVisible && clientId !== 'dlst') &&
                            <CarouselFAB 
                                carouselEndRef={carouselEndRef} 
                                productIdx={productIdx}
                                product={messages.filter(message => message.intent === '제공')}
                                clientId={clientId}
                            />
                        }
                    </div>
                </div>
                {messages.length === 0 &&(
                    <div className={style.Chat__mainmd__onboard}>
                        <div className={compStyle.Spacing__16} />
                        <div className={style.Chat__flex}>
                            <div className={style.Util__w100mw}>
                                <div className={style.Chat__greetingWrap}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div className={style.Chat__greetingContent} style={{width: 'fit-content'}}>
                                            <p 
                                                className='h3'
                                            >
                                                {/* {customSet ? customSet.greetingInput : randomGreeting} */}
                                                <MarkDownConverter text={reqParams.floatingComment} />
                                                {/* {testDesc} */}
                                            </p>
                                        </div>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p 
                                                id="PROD-DESC"
                                                className='h3'
                                                style={{
                                                    maxHeight: (isMoreEnabled ? '95px' : 'fit-content'),
                                                    // maxHeight: '95px',
                                                    // display: '-webkit-box',
                                                    // WebkitLineClamp: 5,
                                                    // WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                {/* {customSet ? customSet.greetingInput : randomGreeting} */}
                                                <MarkDownConverter text={floatingProduct?.text} />
                                                {/* {testDesc} */}
                                            </p>
                                            {
                                                isMoreEnabled &&
                                                <span 
                                                    className='h3' 
                                                    onClick={handleClickMore}
                                                    style={{float: 'right', transform: 'translateY(-18px)', background: '#efefef', paddingLeft: '8px', color: '#999', cursor: 'pointer'}}
                                                >
                                                    ...  더보기
                                                </span>
                                            }
                                        </div>
                                        <div className={compStyle.Spacing__4} />
                                        <div 
                                            className={style.Chat__greetingContent}
                                            onClick={() => {gtagCarouselClick(0, floatingProduct?.product[0].name, clientId, 'sdk');}}
                                        >
                                            <div style={{width: '100%', height: '156px', background: '#fff', borderRadius: '10px', padding: '8px', display: 'flex'}}>
                                                <div style={{width: '105px', height: '100%', backgroundImage: `url(${floatingProduct?.product[0].imageUrl})`, borderRadius: '8px', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                                                <div className={compStyle.Spacing__12} />
                                                <div style={{width: 'calc(100% - 105px)', height: '100%'}}>
                                                    <span style={{height: '16px'}}>
                                                        <img src={getServiceType(floatingProduct?.product[0].serviceType)} height={16} />
                                                    </span>
                                                    <div className={compStyle.Spacing__4} />
                                                    <p className='h6'
                                                        style={{
                                                            maxWidth: '141px',
                                                            minHeight: '33px',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        {floatingProduct?.product[0].name}
                                                    </p>
                                                    <div className={compStyle.Spacing__8} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                    >
                                                        {
                                                            floatingProduct?.product[0].discount !== 0 ?
                                                            <div>
                                                                <p
                                                                    className='h9'
                                                                    style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                                >
                                                                    {addComma(floatingProduct?.product[0].originPrice)}
                                                                </p>
                                                                <div
                                                                    className={styleMsg.Carousel__flexBox}
                                                                    style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                                >
                                                                    <p className='h1' style={{ color: "#ec3e48" }}> {floatingProduct?.product[0].discount}%</p>
                                                                    <div className={compStyle.Spacing__4} />
                                                                    <p className='h1'>{addComma(floatingProduct?.product[0].price)}원</p>
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <div style={{ minHeight: '14px' }} />
                                                                <p className='h1'>
                                                                    {addComma(floatingProduct?.product[0].originPrice)}원
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div
                                                        className={styleMsg.Carousel__flexBox}
                                                        style={{ '--align-items': 'center', '--height': '15px' }}
                                                    >
                                                        <span style={{ height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                            <img src='/img/units/carousel-star-gray.png' width={14} />
                                                        </span>
                                                        <p className='h9' style={{ width: 'fit-content', color: '#999999', wordBreak: 'keep-all' }}>
                                                            {floatingProduct?.product[0].rate.toFixed(1) < 0.1 ? '신규' : `${floatingProduct?.product[0].rate.toFixed(1)} 리뷰 ${floatingProduct?.product[0].reviewNum}개`}
                                                        </p>
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {
                                                            [floatingProduct?.product[0].tags]?.map((tag, idx) => {
                                                                if (tag === undefined) return;
                                                                const textColor = tag.text_color;
                                                                const backgroundColor = tag.background_color;
                                                                const borderColor = tag.border_color;
                                                                return (
                                                                    <span 
                                                                        key={idx}
                                                                        className='badge-font'
                                                                        style={{
                                                                            height: '18px',
                                                                            padding: '3px 4px',
                                                                            border: `1px solid ${borderColor}`,
                                                                            borderRadius: '3px',
                                                                            background: `${backgroundColor}`,
                                                                            color: `${textColor}`,
                                                                            marginRight: '4px',
                                                                        }}
                                                                    >{tag.name}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div 
                                                onClick={() => {
                                                    gtagClickPickup(floatingProduct?.product[0].name, clientId, 'sdk');
                                                    logEvent(userEventURL, apiKey, floatingProduct?.product[0].serviceType === '택배배송' ? 'DeliveryClicked' : 'PickupClicked', chatUserId, floatingProduct?.product[0].itemId, clientId, reqParams.isMobile);
                                                    // window.location.href = floatingProduct?.product[0].productUrl
                                                    sendRedirectStatus(true, floatingProduct?.product[0].productUrl);
                                                }}
                                                style={{width: '100%', height: '48px', background: '#FE5000', borderRadius: '8px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                                            >
                                                {
                                                    floatingProduct?.product[0].serviceType === '택배배송' ?
                                                    <>
                                                        <img src='/img/units/order-delivery.png' width={21} height={18} />
                                                        <div className={compStyle.Spacing__4} />
                                                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '600'}}>배송 주문하기</p>
                                                    </> :
                                                    <>
                                                        <img src='/img/units/sdk-pickup-icon.png' width={21} height={18} />
                                                        <div className={compStyle.Spacing__4} />
                                                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '600'}}>방문픽업 주문하기</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                <div className={compStyle.Spacing__16} />
                                {
                                    isChatStarted && 
                                    <>
                                        <div className={style.Chat__greetingWrap} style={{width: '100%'}}>
                                            <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                            <div style={{width: '100%'}}>
                                                <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                                <div className={compStyle.Spacing__4} />
                                                <div id="HEADER" className={style.Chat__greetingContent} style={{width: 'fit-content'}}>
                                                    <p className='h3'>
                                                        더 자세한 설명이 필요하신가요?
                                                    </p>
                                                </div>
                                                <div className={compStyle.Spacing__16} />
                                                <ExampleGroup
                                                    clientId={'sdk'}
                                                    examples={floatingProduct?.example}
                                                    size={'large'}
                                                    handleButtonClick_send={handleButtonClick_send}
                                                    variant={'A'}
                                                />
                                            </div>
                                        </div>
                                        <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                        <div className={compStyle.Spacing__16} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )}
                {messages.length !== 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <div
                            id="ChatField"
                            className={`${style.Chat__mainmd} ${style.Chat__flex}`}
                            style={{overflowX: 'hidden'}}
                        >
                            <div
                                className={style.Chat__list}
                                role="region"
                                aria-label="대화"
                                aria-live="polite"
                            >
                                <div className={compStyle.Spacing__16} />
                                <div className={style.Chat__greetingWrap__md}>
                                    <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                    <div>
                                        <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                        <div className={compStyle.Spacing__4} />
                                        <div id="HEADER" className={style.Chat__greetingContent}>
                                            <p 
                                                id="PROD-DESC"
                                                className='h3'
                                                style={{
                                                    maxHeight: (isMoreEnabled ? '95px' : 'fit-content'),
                                                    overflowY: 'hidden',
                                                }}
                                            >
                                                {/* {customSet ? customSet.greetingInput : randomGreeting} */}
                                                <MarkDownConverter text={floatingProduct?.text} />
                                                {/* {testDesc} */}
                                            </p>
                                            {
                                                isMoreEnabled &&
                                                <span 
                                                    className='h3' 
                                                    onClick={handleClickMore}
                                                    style={{float: 'right', transform: 'translateY(-18px)', background: '#efefef', paddingLeft: '8px', color: '#999'}}
                                                >
                                                    ...  더보기
                                                </span>
                                            }
                                        </div>
                                        <div className={compStyle.Spacing__4} />
                                        <div 
                                            className={style.Chat__greetingContent}
                                            onClick={() => {gtagCarouselClick(0, floatingProduct?.product[0].name, clientId, 'sdk');}}
                                        >
                                            <div style={{width: '100%', height: '156px', background: '#fff', borderRadius: '10px', padding: '8px', display: 'flex'}}>
                                                <div style={{width: '105px', height: '100%', backgroundImage: `url(${floatingProduct?.product[0].imageUrl})`, borderRadius: '8px', backgroundSize: 'cover', backgroundPosition: 'center'}} />
                                                <div className={compStyle.Spacing__12} />
                                                <div style={{width: 'calc(100% - 105px)', height: '100%'}}>
                                                    <span style={{height: '16px'}}>
                                                        <img src={getServiceType(floatingProduct?.product[0].serviceType)} height={16} />
                                                    </span>
                                                    <div className={compStyle.Spacing__4} />
                                                    <p className='h6'
                                                        style={{
                                                            maxWidth: '141px',
                                                            minHeight: '33px',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        {floatingProduct?.product[0].name}
                                                    </p>
                                                    <div className={compStyle.Spacing__8} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                    >
                                                        {
                                                            floatingProduct?.product[0].discount !== 0 ?
                                                            <div>
                                                                <p
                                                                    className='h9'
                                                                    style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                                >
                                                                    {addComma(floatingProduct?.product[0].originPrice)}
                                                                </p>
                                                                <div
                                                                    className={styleMsg.Carousel__flexBox}
                                                                    style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                                >
                                                                    <p className='h1' style={{ color: "#ec3e48" }}> {floatingProduct?.product[0].discount}%</p>
                                                                    <div className={compStyle.Spacing__4} />
                                                                    <p className='h1'>{addComma(floatingProduct?.product[0].price)}원</p>
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <div style={{ minHeight: '14px' }} />
                                                                <p className='h1'>
                                                                    {addComma(floatingProduct?.product[0].price)}원
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div
                                                        className={styleMsg.Carousel__flexBox}
                                                        style={{ '--align-items': 'center', '--height': '15px' }}
                                                    >
                                                        <span style={{ height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                            <img src='/img/units/carousel-star-gray.png' width={14} />
                                                        </span>
                                                        <p className='h9' style={{ width: 'fit-content', color: '#999999', wordBreak: 'keep-all' }}>
                                                            {floatingProduct?.product[0].rate.toFixed(1) < 0.1 ? '신규' : `${floatingProduct?.product[0].rate.toFixed(1)} 리뷰 ${floatingProduct?.product[0].reviewNum}개`}
                                                        </p>
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        {
                                                            [floatingProduct?.product[0].tags]?.map((tag, idx) => {
                                                                const textColor = tag.text_color;
                                                                const backgroundColor = tag.background_color;
                                                                const borderColor = tag.border_color;
                                                                return (
                                                                    <span 
                                                                        key={idx}
                                                                        className='badge-font'
                                                                        style={{
                                                                            height: '18px',
                                                                            padding: '3px 4px',
                                                                            border: `1px solid ${borderColor}`,
                                                                            borderRadius: '3px',
                                                                            background: `${backgroundColor}`,
                                                                            color: `${textColor}`,
                                                                            marginRight: '4px',
                                                                        }}
                                                                    >{tag.name}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div 
                                                onClick={() => {
                                                    gtagClickPickup(floatingProduct?.product[0].name, clientId, 'sdk');
                                                    logEvent(userEventURL, apiKey, floatingProduct?.product[0].serviceType === '택배배송' ? 'DeliveryClicked' : 'PickupClicked', chatUserId, floatingProduct?.product[0].itemId, clientId, reqParams.isMobile);
                                                    // window.location.href = floatingProduct?.product[0].productUrl
                                                    sendRedirectStatus(true, floatingProduct?.product[0].productUrl);
                                                }}
                                                style={{width: '100%', height: '48px', background: '#FE5000', borderRadius: '8px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                                            >
                                                {
                                                    floatingProduct?.product[0].serviceType === '택배배송' ?
                                                    <>
                                                        <img src='/img/units/order-delivery.png' width={21} height={18} />
                                                        <div className={compStyle.Spacing__4} />
                                                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '600'}}>배송 주문하기</p>
                                                    </> :
                                                    <>
                                                        <img src='/img/units/sdk-pickup-icon.png' width={21} height={18} />
                                                        <div className={compStyle.Spacing__4} />
                                                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '600'}}>방문픽업 주문하기</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                <div className={compStyle.Spacing__16} />
                                {
                                    isChatStarted &&
                                    <div className={style.Chat__greetingWrap__md} style={{width: '100%'}}>
                                        <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                        <div>
                                            <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                            <div className={compStyle.Spacing__4} />
                                            <div id="HEADER" className={style.Chat__greetingContent} style={{width: 'fit-content'}}>
                                                <p className='h3'>
                                                    더 자세한 설명이 필요하신가요?
                                                </p>
                                            </div>
                                        </div>
                                        <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                    </div>
                                }
                                <div className={compStyle.Spacing__16} />
                                {messages?.map(({ id, user, message, example, product, dialId, intent, time, userGroup }, index) => {
                                    return (
                                        <Message
                                            key={index}
                                            id={id}
                                            chatUserId={chatUserId}
                                            user={user}
                                            clientId={clientId}
                                            text={message}
                                            example={index === messages.length - 1 && example}
                                            product={product}
                                            dialId={dialId}
                                            lastIdx={index === (messages.length - 1)}
                                            handleButtonClick_send={handleButtonClick_send}
                                            isMobileDevice={true}
                                            carouselEndRef={carouselEndRef}
                                            intent={intent}
                                            messagesEndRef={messagesEndRef}
                                            time={time}
                                            customSet={customSet}
                                            userGroup={userGroup}
                                            productIdx={productIdx}
                                            setProductIdx={setProductIdx}
                                            isSdk={true}
                                            userEventURL={userEventURL}
                                            apiKey={apiKey}
                                            isMobileView={isMobileView}
                                        >
                                            {' '}
                                        </Message>
                                    );
                                })}
                                {
                                    isGeneratingAnswer &&
                                    <div>
                                        <li className={styleMsg.Message__list__md} style={{ width: 'fit-content' }}>
                                            <img src={customSet ? customSet.logoInput : gentooSymbol} width={32} className={style.Chat__icon} />
                                            <div>
                                                <p className='h9' style={{color: '#999999'}}>{customSet ? customSet.nameInput : '젠투'}</p>
                                                <div className={compStyle.Spacing__4} />
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <div className={styleMsg.Message__content}>
                                                        <div className={styleMsg.Message__text}>
                                                        {
                                                            clientId === 'dlst' ?
                                                            <ProgressDLST isMobileDevice={isMobileView} progressState={'first'} /> :    
                                                            <Progress isMobileDevice={isMobileView} progressState={isSecondaryReply ? 'second' : 'first'} />
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <div className={compStyle.Spacing__16}/>
                                        <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                    </div>
                                }
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {
                isChatGuideVisible &&
                <div style={{width: '100%', height: 'fit-content', position: 'absolute', bottom: '95px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: '178px', height: '33px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#222', borderRadius: '8px'}}>
                        <p className='h6 fc-white'>대화창을 클릭해 보세요 ↓</p>
                    </div>
                </div>
            }
            <div 
                className={style.Chat__bottommd}
                style={{borderTop: '1px solid #efefef'}}
            >
                <div className={style.Chat__inputmd}>
                    <HeaderMobile
                        setMessages={setMessages} 
                        setIsBtnVisible={setIsBtnVisible} 
                        chatUserId={chatUserId}
                        isGeneratingAnswer={isGeneratingAnswer}
                        setIsGeneratingAnswer={setIsGeneratingAnswer}
                        chatURL={chatURL}
                        clientId={clientId}
                        apiKey={apiKey}
                    />
                    <div 
                        className={
                            (!isGeneratingAnswer && !isBSActive) ? style.Chat__input1md : style.Chat__input1md__block
                        }
                    >
                        <input
                            id='chat-input'
                            className={style.Chat__inputfield1}
                            ref={inputRef}
                            onFocus={(e) => handleInputFocus(e)}
                            onBlur={handleInputBlur}
                            onChange={onChange}
                            onKeyUp={handleKeyPress}
                            type='text'
                            placeholder={(!isGeneratingAnswer) ? `더 자세한 설명이 필요하신가요?` : '답변 중에는 입력이 불가능해요'}
                            value={input}
                            aria-level='메시지'
                        />
                        <div className={style.Chat__inputButtonWrap}>
                            {
                                isGeneratingAnswer ?
                                    <CircularProgress
                                        id="LOADING"
                                        size={20}
                                        className={style.Chat__loading}
                                        aria-label="로딩 중"
                                        role="alert"
                                    /> :
                                    <button
                                        className={`${style.Chat__inputButton} send-btn`}
                                        onClick={onClick}
                                        aria-level='전송하기'
                                    >
                                        <img src={isInputFocused ? activeSendIcon : sendIcon} width={24}/>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className={style.Chat__footer__md} ><a href='https://www.gentooai.com'>powered by GENTOO</a></div>
            </div>
        </div>
    );
};

export default ChatFieldBaseSDK