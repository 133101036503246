import { useEffect, useState } from 'react';
import style from '../../style/styles/Chat.module.css';
import msgStyle from '../../style/styles/Message.module.css';
import compStyle from '../../style/styles/Components.module.css';

export const Progress = (props) => {
    const { isMobileDevice, progressState } = props;
    const isFirst = progressState === 'first';

    const progressText = (
        isFirst ?
        [
            '질문을 분석하고 있어요..',
            '알맞은 상품을 고르고 있어요..',
            '답변을 작성 중이에요..',
        ] :
        [
            '더 자세한 설명을 작성 중이에요..'
        ]
    )

    const [progress, setProgress] = useState(progressText[0]);

    useEffect(() => {
        let intervalId;
        let index = 1;

        intervalId = setInterval(() => {
            setProgress(progressText[index]);
            index++;

            if(index === progressText.length) {
                clearInterval(intervalId);
            }
        }, 2000)

        return () => {
            clearInterval(intervalId);
            // setProgress(progressText[0]);
        };
    }, [])

    return (
        <>
            <p className='h3' style={{ color: '#999', paddingBottom: '12px' }}>{isFirst ? progress : "더 자세한 설명을 작성 중이에요.."}</p>
            <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div>
            <div className={compStyle.Spacing__8} />
            <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap} style={{ width: '50%' }}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div>
        </>
    )
}

export const ProgressDLST = (props) => {
    const { isMobileDevice, progressState } = props;
    const isFirst = progressState === 'first';

    const contents = [
            {type: 'gentoo', text: '젠투가 얼음조각 밑을 확인 중 …'},
            {type: 'gentoo', text: '스노클링하며 얼음 속을 헤집는 중…'},
            {type: 'gentoo', text: '눈밭을 날개로 뒤적뒤적하는 중…'},
            {type: 'gentoo', text: '눈사람에게 행방을 물어보는 중….'},
            {type: 'gentoo', text: '최신 기술을 동원해 제품을 찾는 중 …'},
            {type: 'gentoo', text: '대서양을 가로지르며 초고속으로 헤엄치는 중…'},
            {type: 'gentoo', text: '젠투 펭귄은 최대 시속 36km까지 수영할 수 있는 펭귄이에요'},
            {type: 'gentoo', text: '펭귄도 6천만 년 전에는 하늘을 날 수 있었어요'},
            {type: 'gentoo', text: '젠투, 열심히 머리 굴리는 중…'},
            {type: 'gentoo', text: '젠투, 안 쓰던 머리 갑자기 쓰는 중…'},
            {type: 'gentoo', text: '젠투, 열심히 상품 검색 중…'},
            {type: 'gentoo', text: '맥주는 차갑게 마셔야 제 맛을 느낄 수 있어요!'},
            {type: 'gentoo', text: '와인과 치즈는 완벽한 페어링이죠!'},
            {type: 'gentoo', text: '젠투, 술 향에 취하는 중..'},
            {type: 'gentoo', text: '와인과 초콜릿은 달콤한 조합이죠!'},
            {type: 'gentoo', text: '주류 리스트를 꼼꼼히 읽느라 시간이 걸려요..'},
            {type: 'info', text: '인류가 언제 처음 술을 마셨는지 아시나요?'},
            {type: 'info', text: '인류가 처음 술을 마신 시기는 아무도 알 수 없습니다.'},
            {type: 'info', text: '메소포타미아와 이집트 기록에 포도주가 등장합니다.'},
            {type: 'info', text: '세계 3대 위스키 제조국은 스코틀랜드, 아일랜드, 미국이에요.'},
            {type: 'info', text: '위스키는 Whisky 일까요, Whiskey 일까요?'},
            {type: 'info', text: '위스키의 어원은 ‘생명의 물’이라는 뜻의 게일어로 ‘uisge beatha’예요.'},
            {type: 'info', text: '알코올이 1% 미만일 경우 비알코올로 분류됩니다.'},
            {type: 'info', text: '알코올이 전혀 없을 경우 무알코올로 분류됩니다.'},
            {type: 'info', text: '비알코올과 무알코올은 성인용으로 판매됩니다.'},
            {type: 'info', text: '세계적으로 유명한 와인 생산국은 프랑스와 이탈리아입니다.'},
            {type: 'info', text: 'Vivino는 사용자 리뷰를 기반으로 와인을 평가합니다.'},
            {type: 'info', text: 'Vivino는 세계 최대의 와인 커뮤니티입니다.'},
        ];

    const randomIdx = Math.floor(Math.random() * contents.length);

    const progressText = (
        isFirst ?
        [
            '질문을 분석하고 있어요..',
            '알맞은 상품을 고르고 있어요..',
            '답변을 작성 중이에요..',
        ] :
        [
            '더 자세한 설명을 작성 중이에요..'
        ]
    )

    const [progress, setProgress] = useState(progressText[0]);

    // useEffect(() => {
    //     let intervalId;
    //     let index = 1;

    //     intervalId = setInterval(() => {
    //         setProgress(progressText[index]);
    //         index++;

    //         if(index === progressText.length) {
    //             clearInterval(intervalId);
    //         }
    //     }, 2000)

    //     return () => {
    //         clearInterval(intervalId);
    //         // setProgress(progressText[0]);
    //     };
    // }, [])

    return (
        <div style={{padding: '0 14px'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <img src='/img/units/loading-anime.png' width={140} height={140} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                    contents[randomIdx].type === 'info' &&
                    <>
                        <img src='/img/units/skeleton-tip.png' width={35} height={18} />
                        <div className={compStyle.Spacing__8} />
                    </>
                }
                <p className='h6' style={{ color: '#999', paddingBottom: '12px', maxWidth: '206px' }}>{contents[randomIdx].text}</p>
            </div>
            {/* <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div>
            <div className={compStyle.Spacing__8} />
            <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap} style={{ width: '50%' }}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div> */}
        </div>
    )
}

export const SkeletonCarousel = (props)  => {
    const { isMobileDevice } = props;
    return (
        <div id='carousel-container' className={isMobileDevice ? msgStyle.Carousel__container__md : msgStyle.Carousel__container}>
            <div style={{position: 'relative'}}>
                <div
                    className={msgStyle.Carousel__flexBox}
                    style={{ '--justify-content': 'flex-start' }}
                >
                    {
                        [0,1,2,3,4].map((ord, idx) => {
                            return (
                                <div key={idx}>
                                    <div
                                        className={msgStyle.Carousel__wrap}
                                    >
                                        <div className={msgStyle.Carousel__topWrap}>
                                            <div className={msgStyle.Skeleton__BarWrap} style={{'--width': '150px', '--height': '150px'}}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div className={msgStyle.Skeleton__BarWrap} style={{'--width': '75px'}}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div className={msgStyle.Skeleton__BarWrap}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div className={msgStyle.Skeleton__BarWrap}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div className={msgStyle.Skeleton__BarWrap}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                            <div className={compStyle.Spacing__32} />
                                            <div className={msgStyle.Skeleton__BarWrap} style={{'--width': '75px'}}>
                                                <div className={msgStyle.Skeleton__Bar} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={compStyle.Spacing__4} />
            </div>
        </div>
    )
}