import React, { useEffect, useState } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Message.module.css';
import { CarouselCkat, CarouselDemo, CarouselDemoHori, CarouselDlst, CarouselDlstHori, CarouselVimlHori } from '../Custom/Carousel.jsx';
import ExampleGroup from '../Chat/Example';
import { fetchExample, fetchExampleAnchovy } from '../../utils/apis';
import MarkDownConverter from '../Markdown';


const Message = (props) => {
    let { 
        id, 
        user,
        chatUserId,
        partnerId,
        text, 
        clientId, 
        example = undefined, 
        product, 
        dialId, 
        lastIdx = false, 
        handleButtonClick_send, 
        isMobileDevice, 
        carouselEndRef, 
        intent, 
        messagesEndRef, 
        time, 
        customSet = false, 
        isIOS = false, 
        userGroup, 
        productIdx, 
        setProductIdx, 
        isSdk = false, 
        userEventURL, 
        apiKey, 
        isMobileView = false } = props;
    const isMyMessage = user !== 'Accio';
    const [ shouldFollowup, setShouldFollowup ] = useState(false);
    const [ showExam, setShowExam ] = useState(false);
    const storedItemId = sessionStorage.getItem('itemId')
    const [ genExam, setGenExam ] = useState([example, storedItemId ? storedItemId : 'general']);
    const fetchExamFunc = (clientId === 'dlst' ? fetchExample : fetchExampleAnchovy);
    console.log('userEventUrl @ Message', userEventURL);

    // timestamp for QA (apply for dev only)
    const isDev = window.location.hostname.includes('dev-demo');

	let borderStyle = (
        isMobileView ? 
        (isMyMessage ? style.Message__listmy__md : style.Message__list__md) :
        (isMyMessage ? style.Message__listmy : style.Message__list)
    );
    
    const carouselComp = {
        demo: (customSet && customSet?.carouselDirInput === 'carousel-horizontal' ? CarouselDemoHori : CarouselDemo),
        ckat: CarouselCkat,
        fdko_wine: null,
        dlst: CarouselDlstHori,
        bala: CarouselDlst,
        nose: CarouselDlst,
        viml: (customSet && customSet?.carouselDirInput === 'carousel-horizontal' ? CarouselVimlHori : CarouselDlst),
        pick: CarouselDlst,
        cafe24: CarouselDlstHori,
        dummy: CarouselDlstHori,
        test: CarouselDlstHori,
    };

    let Carousel = carouselComp[clientId];
    // let Carousel = carouselComp['dlst'];

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'auto' });
    };

    const dummyExam = [
        '비슷한 상품 추천 받기',
        '사용자 리뷰',
        '상품 특장점'
    ]

    useEffect(() => {
        scrollToBottom();
    }, [shouldFollowup, showExam])

    useEffect(() => {
        if (!lastIdx) {
            setShouldFollowup(false);
            return
        } else if (intent === '제공상세' || intent === '정보' || intent === '제공') {
            const timeoutFollowup = setTimeout(() => {
                const itemId = sessionStorage.getItem('itemId')
                const clientParams = clientId === 'dlst' ? clientId : partnerId;
                fetchExamFunc(chatUserId, clientParams, itemId)
                .then(res => {
                        if (res.length === 0) {
                            setGenExam([dummyExam, itemId])
                            if (product.length > 0) sessionStorage.setItem('itemId', itemId)
                        } else {
                            setGenExam([res, itemId])
                            if (product.length > 0) sessionStorage.setItem('itemId', itemId)
                        }
                    });
                setShouldFollowup(true);
            }, 7000);

            const timeoutExam = setTimeout(() => {
                setShowExam(true);
            }, 8000);

            return () => {
                clearTimeout(timeoutFollowup);
                clearTimeout(timeoutExam);
            }
        } else if (intent === '일반' || intent === '추천' || intent === '피드백') {
            setShowExam(true);
        }
    }, [intent])

	// user chat message return
    if (product && product.length !== 0) {
        return (
            <>
                {
                    isMyMessage ?
                    <li className={borderStyle}>
                        <div className={style.Message__content__my}>
                            <div className={style.Message__text__my}>
                                <p className='h3'>{text}</p>
                                {isDev && <p>{time}</p>}
                            </div>
                        </div>
                    </li> :
                    <>
                        <li className={borderStyle}>
                            <img src={customSet ? customSet?.logoInput : '/img/units/gentoo-symbol.png'} width={32} height={32} className={style.Message__icon} />
                            <div>
                                <p className='h9' style={{color: '#999999'}}>{customSet ? customSet?.nameInput : '젠투'}</p>
                                <div className={compStyle.Spacing__4} />
                                <div className={style.Message__content}>
                                    <div className={style.Message__text}>
                                        {   
                                            text.map((list, idx) => <p key={idx} className='h3'><MarkDownConverter text={list} /></p>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div className={compStyle.Spacing__8} />
                        {
                            product.length !== 0 &&
                            <Carousel 
                                id={id}
                                product={product} 
                                dialId={dialId} 
                                isMobileDevice={isMobileDevice} 
                                carouselEndRef={carouselEndRef} 
                                colorInput={customSet?.colorInput}
                                recommendSizeInput={customSet?.recommendSizeInput}
                                clientId={clientId}
                                partnerId={partnerId}
                                chatUserId={chatUserId}
                                userGroup={userGroup}
                                setGenExam={setGenExam}
                                fetchExample={fetchExamFunc}
                                productIdx={productIdx}
                                setProductIdx={setProductIdx}
                                userEventURL={userEventURL}
                                apiKey={apiKey}
                            />
                        }
                        <div className={compStyle.Spacing__8} />
                        {
                            (product.length !== 0 && product[0].summary) &&
                            <li className={borderStyle}>
                                <img src={customSet ? customSet?.logoInput : '/img/units/gentoo-symbol.png'} width={32} height={32} className={style.Message__icon} />
                                <div>
                                    <p className='h9' style={{color: '#999999'}}>{customSet ? customSet?.nameInput : '젠투'}</p>
                                    <div className={compStyle.Spacing__4} />
                                    <div className={style.Message__content}>
                                        <div className={style.Message__text}>
                                            <p className='h3'><MarkDownConverter text={`💡**${product[0].name} AI 리뷰요약:** ${product[0].summary}`} /></p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        }
                        <div className={compStyle.Spacing__8} />
                        {
                            (shouldFollowup && lastIdx) &&
                            <>
                                <div className={borderStyle}>
                                    <img src={customSet ? customSet?.logoInput : '/img/units/gentoo-symbol.png'} width={32} height={32} className={style.Message__icon} />
                                    <div>
                                        { !isMyMessage && <p className='h9' style={{color: '#999999'}}>{customSet ? customSet?.nameInput : '젠투'}</p> }
                                        <div className={compStyle.Spacing__4} />
                                        <div className={style.Message__content} style={{width: 'fit-content'}}>
                                            <div className={style.Message__text}>
                                                {
                                                    intent === '제공' &&
                                                    <p className='h3'>더 자세한 설명이 필요하신가요?</p>
                                                }
                                            </div>
                                        </div>    
                                        <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                    </div>
                                </div>
                                <div className={compStyle.Spacing__16} />
                                {
                                    (showExam && example?.length > 0) &&
                                    <ExampleGroup 
                                        clientId={clientId}
                                        examples={genExam[0]}
                                        size={isMobileView ? 'small' : 'large'}
                                        handleButtonClick_send={handleButtonClick_send}
                                        intent={intent}
                                        variant={(intent === '제공' && clientId === 'dlst') ? 'B' : 'A'}
                                        isSdk={isSdk}
                                    />
                                }
                            </>
                        }
                        <div className={compStyle.Spacing__8} />
                    </>

                }
            </>
        )
    } else {
        return (
            <>
                {
                    isMyMessage ?
                    <>
                        <li className={borderStyle}>
                            <div className={style.Message__content__my}>
                                <div className={style.Message__text__my}>
                                    <p className='h3'>{text}</p>
                                    {isDev && <p>{time}</p>}
                                </div>
                            </div>
                        </li> 
                        <div className={compStyle.Spacing__16} />
                    </> :
                    <>
                        <div className={borderStyle}>
                            <img src={customSet ? customSet?.logoInput : '/img/units/gentoo-symbol.png'} width={32} height={32} className={style.Message__icon} />
                            <div>
                                { !isMyMessage && <p className='h9' style={{color: '#999999'}}>{customSet ? customSet?.nameInput : '젠투'}</p> }
                                <div className={compStyle.Spacing__4} />
                                <div className={isMyMessage ? style.Message__content__my : style.Message__content}>
                                    <div className={style.Message__text}>
                                        {
                                            intent === '제공상세' ?
                                            text?.map((list, idx) => 
                                                <li 
                                                    key={idx} 
                                                    className={`${style.Message__bulletList} h3`} 
                                                    style={{marginBottom: (idx === text?.length - 1 && 0) }}
                                                >
                                                    <MarkDownConverter text={list} />
                                                </li>
                                            ) :
                                            // TODO: margin bottom applied to only md size 
                                            text.map((list, idx) => <p key={idx} className='h3'><MarkDownConverter text={list} /></p>)
                                        }
                                    </div>
                                </div>
                                {
                                    (shouldFollowup && lastIdx) &&
                                    <>
                                        <div className={compStyle.Spacing__4} />
                                        <div className={style.Message__content} style={{width: 'fit-content'}}>
                                            <div className={style.Message__text}>
                                                {
                                                    (intent === '제공상세') &&
                                                    <p className='h3'>더 자세한 추천이 필요하신가요? 말씀주시면 다른 것도 찾아볼게요.</p>
                                                }
                                                {
                                                    (intent === '제공' && clientId === 'dlst') &&
                                                    <p className='h3'>더 원하는 정보???</p>
                                                }
                                                {
                                                    (intent === '정보' || intent === '일반') &&
                                                    <p className='h3'>더 궁금하신 내용은 없으신가요?</p>
                                                }
                                            </div>
                                        </div>    
                                        <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                    </>
                                }
                            </div>
                        </div>
                        <div className={compStyle.Spacing__16} />
                        {
                            (showExam && example?.length > 0) &&
                            <div>
                                <ExampleGroup 
                                    clientId={clientId}
                                    examples={genExam[0]}
                                    size={isMobileView ? 'small' : 'large'}
                                    handleButtonClick_send={handleButtonClick_send}
                                    intent={intent}
                                    variant={intent === '제공' ? 'B' : 'A'}
                                    isSdk={isSdk}
                                />
                                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
                                <div className={compStyle.Spacing__16} />
                            </div>
                        }
                    </>

                }
                <div ref={messagesEndRef} style={{ minHeight: '1px' }} />
            </>
        )
    }
};

export default Message